import FormAction from "@/components/base/form-action"
import TitleWrapper from "@/components/base/title-wrapper"
import useStyles from "@/components/features/multi-business/styles"
import useLogic from "@/hooks/google_multiple/useLogic"
import useFormater from "@/hooks/useFormater"
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import Icon from "@/components/base/Icon"

import FilterForm from "@/components/features/multi-business/form/filter-form"
import ConsentModal from "@/components/features/multi-business/modal"
import GoogleStore from "@/store/google"
import ResellerStore from "@/store/reseller"
import { useMemo } from "react"

const ConnectAccounts = ({ text1, text2, btnTxt, handlePrevious }) => {
    return (
        <Stack
            flexDirection="column"
            justifyContent="center"
            gap={1}
            sx={{
                height: "100%",
            }}
        >
            <Typography
                sx={{
                    textAlign: "center",
                    color: "text.secondary",
                }}
            >
                {text1}
            </Typography>
            <Typography
                sx={{
                    textAlign: "center",
                    color: "text.secondary",
                }}
            >
                {text2}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{
                    width: "max-content",
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                }}
                onClick={() => handlePrevious()}
            >
                <Icon name="unicolorGoogle" />
                <span>{btnTxt}</span>
            </Button>
        </Stack>
    )
}

const ConnectMessage = ({
    missing_token,
    reseller_name,
    compte_name,
    handlePrevious,
}: {
    missing_token: boolean
    reseller_name: string
    compte_name: string
    handlePrevious: () => void
}) => {
    const { t } = useTranslation()
    return (
        <ConnectAccounts
            text1={
                !missing_token
                    ? t("BUSINESSES.OAUTH.NO_LOCATION")
                    : t("BUSINESSES.OAUTH.RE_LOCATION", {
                          reseller_name,
                          compte_name,
                      })
            }
            text2={!missing_token ? t("BUSINESSES.OAUTH.SELECT_ACCOUNT") : t("BUSINESSES.OAUTH.RE_SELECT_ACCOUNT")}
            btnTxt={!missing_token ? t("BUSINESSES.OAUTH.CONNECT") : t("BUSINESSES.OAUTH.RE_CONNECT")}
            handlePrevious={handlePrevious}
        />
    )
}

const ProgressComponent = () => {
    return (
        <Stack
            className="list-wrapper"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                textAlign: "center",
            }}
        >
            <CircularProgress />
        </Stack>
    )
}

const AuthGooglev2 = ({ mockData = null }: { mockData?: any }) => {
    const { t } = useTranslation()
    const { classes } = useStyles({ props: { haveTop: false } })

    const { countBusinesses, isSync, handleToggleCheckbox, handleSync, handleCancel, handlePrevious } = useLogic()
    const { resellerName } = ResellerStore()
    const {
        selected_businesses,
        missing_scope,
        locations,
        accounts,
        is_fetching,
        page_loading,
        missing_token,
        compte_name,
        update: updateGoogle,
    } = GoogleStore()
    const { parseAddress } = useFormater()
    const locationsList = useMemo(() => {
        return mockData?.locations ? mockData?.locations : locations
    }, [locations, mockData])

    const accountsList = useMemo(() => {
        return mockData?.locations ? mockData?.accounts : accounts
    }, [accounts, mockData])

    return (
        <>
            {!page_loading && (
                <Box className={classes.container}>
                    <Box className="wrapper">
                        <Stack direction={{ sm: "row" }} gap={2} justifyContent={"space-between"}>
                            <TitleWrapper
                                handlePreviousButton={handlePrevious}
                                title={t("BUSINESSES.OAUTH.SELECT_ACCOUNTS")}
                                subtitle={false}
                            />
                        </Stack>
                        <Box className="list-wrapper">
                            {!page_loading && <FilterForm disabled={isSync} />}

                            {locationsList?.length === 100 && (
                                <Typography className="search-limit" component="span">
                                    {t("BUSINESSES.OAUTH.LIMIT")}
                                </Typography>
                            )}

                            {is_fetching && <ProgressComponent />}
                            {!is_fetching && (
                                <>
                                    <List data-testid="locations-list" data-e2e="locations-list">
                                        {locationsList?.map((value) => (
                                            <ListItemButton
                                                key={value.name}
                                                onClick={() => handleToggleCheckbox(value.name)}
                                                className={selected_businesses.includes(value.name) ? "selected" : ""}
                                                disabled={value.is_connected}
                                                sx={{
                                                    background: "white",
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={selected_businesses.includes(value.name)}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={value.locationName}
                                                    secondary={
                                                        value.address?.regionCode
                                                            ? parseAddress({
                                                                  region: value.address?.administrativeArea || "",
                                                                  countryCode: value.address?.regionCode,
                                                                  address: value.address?.addressLines?.length
                                                                      ? value.address?.addressLines[0]
                                                                      : "",
                                                                  city: value.address?.locality || "",
                                                                  zip: value.address?.postalCode || "",
                                                              })
                                                            : ""
                                                    }
                                                />
                                                {value.is_connected && (
                                                    <ListItemIcon>
                                                        <Icon name="link" />
                                                    </ListItemIcon>
                                                )}
                                            </ListItemButton>
                                        ))}
                                    </List>
                                    {accountsList?.length !== 0 && locationsList?.length === 0 && (
                                        <ConnectMessage
                                            missing_token={missing_token}
                                            reseller_name={resellerName}
                                            compte_name={compte_name}
                                            handlePrevious={() => handlePrevious(true)}
                                        />
                                    )}
                                    {accountsList?.length === 0 && (
                                        <ConnectMessage
                                            missing_token={missing_token}
                                            reseller_name={resellerName}
                                            compte_name={compte_name}
                                            handlePrevious={() => handlePrevious(true)}
                                        />
                                    )}
                                    {!isSync && (
                                        <Typography
                                            component="span"
                                            className="selected-length"
                                            sx={{
                                                minHeight: "24px",
                                            }}
                                        >
                                            {selected_businesses.length > 0 && (
                                                <>
                                                    {selected_businesses.length} {t("BUSINESSES.OAUTH.SELECTED_LENGTH")}
                                                </>
                                            )}
                                        </Typography>
                                    )}
                                    {isSync && (
                                        <Typography component="span" className="selected-length">
                                            {countBusinesses} {t("BUSINESSES.OAUTH.of")} {selected_businesses.length}{" "}
                                            {t("BUSINESSES.OAUTH.CONNECTED_LENGTH")}
                                        </Typography>
                                    )}
                                </>
                            )}
                            <FormAction
                                handleSubmit={handleSync}
                                handleCancel={handleCancel}
                                isDisabled={is_fetching || isSync || !selected_businesses.length}
                                isLoading={isSync}
                            />
                        </Box>

                        <ConsentModal
                            open={missing_scope}
                            onClose={() =>
                                updateGoogle({
                                    missing_scope: !missing_scope,
                                })
                            }
                            onRetry={handlePrevious}
                        />
                    </Box>
                </Box>
            )}
        </>
    )
}

export default AuthGooglev2
