import env from "@/config/env"
import ResellerStore, { INotificationSetter, INotificationStore } from "@/store/notification"
import UiStore from "@/store/ui"

class NotificationService {
    getState = (): INotificationStore & INotificationSetter => {
        return ResellerStore.getState()
    }

    setState = (update: Partial<INotificationStore>): void => {
        const { update: updateState } = this.getState()
        updateState(update)
    }

    showNewVersion = (show: boolean): void => {
        const localKey = `${env.APP_NAME}-${env.ENV}-show-new-version`
        const { setShowNewVersion } = UiStore.getState()

        const localShowVersion = localStorage.getItem(localKey)
        if (!localShowVersion || localShowVersion === undefined) {
            localStorage.setItem(localKey, "false")
        } else {
            setShowNewVersion(show)
            localStorage.setItem(localKey, String(show))
        }
    }

    updateAppVersion = (version: string): void => {
        localStorage.setItem(`${env.APP_NAME}-${env.ENV}-version`, version)
    }
}

export default new NotificationService()
