import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import { updateButtonSx } from "@/components/features/multi-business/styles"
import { useDebounce } from "@/hooks/useDebounce"
import useNotification from "@/hooks/useNotification"
import { Button, CircularProgress, Paper, Stack } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface FilterFormProps {
    disabled: boolean
    onRefresh: (filter: string) => void
    addAccount: () => void
}

const FilterFormIg = (props: FilterFormProps) => {
    const { disabled = false, onRefresh, addAccount } = props
    const { t } = useTranslation()
    const [filter, setFilter] = useState("")
    const [refresh, setRefresh] = useState(false)
    const { notif } = useNotification()
    const searchValue = useDebounce(filter, 400)

    const refreshData = useCallback(() => {
        onRefresh(searchValue)
    }, [searchValue, onRefresh])

    useEffect(() => {
        if (searchValue) {
            refreshData()
        }
    }, [searchValue, refreshData])
    return (
        <Paper elevation={0} sx={{ p: 1 }}>
            <Stack flexDirection={"row"} gap={3}>
                <form
                    className="filter-inputs"
                    data-e2e="fb-filter"
                    data-testid="fb-filter"
                    onSubmit={(e) => {
                        e.preventDefault()
                    }}
                >
                    <BaseInput
                        label={t("BUSINESSES.OAUTH.SEARCH")}
                        data-testid="search"
                        disabled={refresh ?? disabled}
                        onInput={(event) => setFilter(event.target.value)}
                        sx={{
                            maxWidth: "240px",
                            minWidth: "240px",
                        }}
                    />
                </form>
                <Button variant="text" sx={updateButtonSx} onClick={refreshData}>
                    <Icon name="refresh" />
                    {t("UPDATE")}
                </Button>
                <Button
                    variant={"contained"}
                    data-testid="create"
                    type="submit"
                    disabled={disabled}
                    onClick={addAccount}
                >
                    {refresh && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                    {!refresh && <Icon name={"plus"} sx={{ marginRight: "8px" }} />}
                    {t("BUSINESSES.INSTAGRAM_AUTH.ADD_ACCOUNT")}
                </Button>
            </Stack>
        </Paper>
    )
}

export default FilterFormIg
