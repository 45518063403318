import Notification from "@/components/widget/Notification"
import useUtilsService from "@/hooks/useBase64"
import useFormater from "@/hooks/useFormater"
import { ResellerService } from "@/services"
import LangService from "@/services/lang"
import NotificationStore from "@/store/notification"
import ResellerStore from "@/store/reseller"
import UiStore from "@/store/ui"
import Cookies from "js-cookie"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useSearchParams } from "react-router-dom"
const ProvidersWithOutlet = () => {
    const { resellerName, resellerFetched } = ResellerStore()
    const { title, favicon, preferredLanguage, updateState } = UiStore()
    const { i18n, t, ready } = useTranslation()
    const { update: updateNotifState } = NotificationStore()
    const { hash, pathname } = useLocation()
    const [searchParams] = useSearchParams()
    const { decode64AndParse } = useUtilsService()
    const { queryStringToObject } = useFormater()

    const domainUser = useMemo(() => {
        let state = null
        if (hash) {
            const data = queryStringToObject(hash.replace("#", ""))
            if (data?.state) {
                state = decode64AndParse(data?.state)
            }
        }
        if (Cookies.get("facebook_metadata")) {
            const query = JSON.parse(Cookies.get("facebook_metadata"))
            state = query?.state
        }

        if (Cookies.get("google_metadata")) {
            const query = JSON.parse(Cookies.get("google_metadata"))
            state = query
        }
        if (searchParams.get("state")) {
            state = decode64AndParse(searchParams.get("state"))
        }

        return state?.cb?.replace("/businesses", "")
    }, [hash, searchParams])

    useEffect(() => {
        document.title = title ? `${resellerName ?? ""} - ${t(title)}` : resellerName ?? ""
    }, [resellerName, t, title])

    useEffect(() => {
        if (favicon) {
            const link = document.querySelector("link#favicon")
            if (link) link.setAttribute("href", favicon + "?v=2")
        }
    }, [favicon])

    useEffect(() => {
        function handleLanguageChange() {
            const language = LangService.preloadLanguage(navigator.languages)
            updateState({ preferredLanguage: language })
        }
        window.addEventListener("languagechange", handleLanguageChange)
        return () => {
            window.removeEventListener("languagechange", handleLanguageChange)
        }
    }, [updateState])

    useEffect(() => {
        const language = LangService.preloadLanguage(navigator.languages)
        document.documentElement.lang = language
        Cookies.set("lang", language)
        updateState({ preferredLanguage: language })
    }, [updateState])

    useEffect(() => {
        //TODO: optimiser l'appel à resellerInfos
        const getReseller = async () => {
            const reseller = await ResellerService.getResellerInfos(domainUser)
            ResellerService.setState({ ...reseller, resellerFetched: true })
        }

        if (!resellerFetched) {
            getReseller()
        }
        updateNotifState({ show: false })
    }, [pathname, resellerFetched, updateNotifState])

    useEffect(() => {
        i18n.changeLanguage(preferredLanguage)
    }, [preferredLanguage, i18n])

    return (
        <React.Fragment>
            {ready && (
                <>
                    <Notification />
                    <Outlet />
                </>
            )}
        </React.Fragment>
    )
}

export default ProvidersWithOutlet
