import logoDilypse from "@/assets/Logo-Dilypse.png"
import FormAction from "@/components/base/form-action"
import TitleWrapper from "@/components/base/title-wrapper"
import useStyles from "@/components/features/multi-business/styles"
import useLogic from "@/hooks/facebook/useLogic"
import { Box, CircularProgress, FormControlLabel, List, Radio, RadioGroup } from "@mui/material"
import { useTranslation } from "react-i18next"

import Icon from "@/components/base/Icon"
import ResellerStore from "@/store/reseller"
import classNames from "classnames"

const AuthFacebook = ({ mockData = null }: { mockData?: any }) => {
    const { t } = useTranslation()
    const { classes } = useStyles({ props: { haveTop: true } })
    const { handleCancel, pages, handleSync, isFetching, isLoading, selectedPage, setSelectedPage, handlePrevious } =
        useLogic()
    const { resellerLogo, resellerName, resellerFetched } = ResellerStore()

    return (
        <Box className={classNames(classes.container, "facebook")}>
            <Box className="logo-wrapper">
                {resellerFetched && <img src={resellerLogo || logoDilypse} alt={resellerName || ""} />}
            </Box>
            <Box className={classNames("wrapper", pages?.length === 0 && "")}>
                <TitleWrapper
                    handlePreviousButton={handlePrevious}
                    title={t("BUSINESSES.FACEBOOK_AUTH.TITLE")}
                    type="facebook"
                    subtitle={true}
                />
                {isFetching && (
                    <Box className="list-wrapper" sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                )}
                {!isFetching && (mockData || pages)?.length > 0 && (
                    <Box className="list-wrapper">
                        <RadioGroup
                            onChange={(event) => setSelectedPage(event.target.value)}
                            defaultValue={selectedPage}
                            aria-labelledby="radio-buttons-group-label"
                            name="radio-buttons-group"
                            sx={{
                                "& .MuiSvgIcon-root": {
                                    fill: "#0180FF!important",
                                },
                            }}
                        >
                            <List>
                                {(mockData || pages)?.map((page, kkey) => (
                                    <div className="radio-element" key={kkey} data-testid="page-facebook-item">
                                        <FormControlLabel
                                            sx={{ width: "100%" }}
                                            value={page?.id}
                                            control={<Radio disabled={page.is_connected} />}
                                            label={page?.name}
                                        />
                                        {page.is_connected && <Icon name="link" />}
                                    </div>
                                ))}
                            </List>
                        </RadioGroup>
                        <FormAction
                            handleSubmit={handleSync}
                            handleCancel={handleCancel}
                            isDisabled={isFetching || !selectedPage}
                            isLoading={isLoading}
                            isFacebook={true}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default AuthFacebook
